import React from 'react';
import './App.scss';
import showreel from './content/showreel.mp4';

function App() {
  return (
    <div className="App">
      <div className="Opening">
        
        <div className="titleText">

          <p className="topText">COMING</p>
          <p className="bottomText">SOON</p>
          
        </div>

        <video className='BGVideo' src={showreel} muted autoPlay loop/>

      </div>
      
      {/* <div className="lowercontent">

            <div className="Showreel">
                <h1>SHOWREEL</h1>
                  <video className='ShowreelVideo' src={showreel} controls/>
            </div>

            <div className="Seperator" />

            <div className="AboutMe">
                <h1>ABOUT ME</h1>
                  
            </div>
      </div> */}

    </div>
  );
}

export default App;
